import "./App.css";
import React, { useEffect, useState } from "react";
import Navbar from "./Components/Navbar/Navbar";
import HomePage from "./Components/Homepage/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";// Adjust path as needed
function App() {

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
